export default {
    title: 'Status ändern',

    toolExplanation: 'Ändern Sie den Status der ausgewählten Kostenstellen auf Aktiv oder Inaktiv.',

    activateAction: 'Keine Kostenstelle aktivieren | {count} Kostenstelle aktivieren | {count} Kostenstellen aktivieren',
    deactivateAction: 'Keine Kostenstelle deaktivieren | {count} Kostenstelle deaktivieren | {count} Kostenstellen deaktivieren',

    activatedCostCenters: 'Die Kostenstellen wurden erfolgreich aktiviert.',
    deactivatedCostCenters: 'Die Kostenstellen wurden erfolgreich deaktiviert.'
}