import route from 'ziggy-js'
import { defineNuxtPlugin } from '#app'

//Hole die generierten Routes aus dem geteilten globalstorage-Ordner
import {Ziggy} from '@/globalstorage/ziggy.js'

//Override Base URL, because we want to use base URL defined in axiosBase.js Plugin
Ziggy.baseUrl = '/'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('apiRoute', (name, params, absolute) => route(name, params, absolute, Ziggy))
})