export default {
    title: 'Beschaffungen',
    order_number: 'BA.-Nummer',
    type: 'Art',
    name: 'Bezeichnung',
    sum: 'Betrag',
    user_id: 'Mitarbeiter',
    category_id: 'Kategorie',
    cost_center_id: 'Kostenstelle',
    created_at: 'Vom',
    status: 'Status',
    open: 'Entwurf',
}