export default {
    newest: 'Newest',
    oldest: 'Oldest',
    noEntries: 'No entries found',

    demo: {
        openAi: "Open Ai Demo"
    },

    admins: {
        title: "Admins",
        tableIndex: "Overview Admins"
    },

    organisations: {
        tableIndex: "Overview Organisations"
    }
}