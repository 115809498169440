import table from './table'

export default {
    baseData: 'Grunddaten',
    name: 'Bezeichnung',
    buyerReference: 'Kostenstelle',
    activeUntil: 'Aktiv bis (einschließlich)',
    managers: 'Kostenstellenverantwortliche',
    noManagersFound: 'Keine Verantwortlichen hinterlegt',

    toggleStatus: 'Status umschalten',
    toggleStatusSuccess: 'Status wurde erfolgreich umgeschaltet.',
    toggleStatusError: 'Der Status konnte nicht umgeschaltet werden.',
    
    deleteCostCenter: 'Kostenstelle löschen',
    deleteCostCenterText:
        'Möchtest du die Kostenstelle wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteCostCenterSuccess: 'Kostenstelle wurde erfolgreich gelöscht.',
    deleteCostCenterError: 'Die Kostenstelle konnte nicht gelöscht werden.',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    table
}