import {I18n} from "vue-i18n"
import {RouteLocationRaw} from "vue-router"

interface Variants {
    success: string;
    error: string;
    warning: string;
    info: string;
}

interface Action {
    name: string,
    callback: () => void
}

export type Notification = {
    id: number;
    title: string;
    message?: string;
    type: keyof Variants;
    icon?: string;
    action?: Action;
    timeout: number;
    permanent: boolean;
    pauseOnHover: boolean
}

let id = 0

export const useNotification = () => {
    const DEFAULT_TIMEOUT = 2500
    const notifications = useState<Notification[]>("notifications", () => [])

    const queueNotification = (
        title: string,
        type: keyof Variants,
        timeout = DEFAULT_TIMEOUT,
        message?: string,
        icon?: string,
        action?: Action,
        permanent?: boolean,
        pauseOnHover?: boolean
    ) => {
        const notification: Notification = {
            id: id++,
            title,
            message,
            icon,
            action,
            type,
            timeout,
            permanent: !!permanent,
            pauseOnHover: pauseOnHover ?? true
        }
        notifications.value.push(notification)
    }

    /**
     * Will only be used by the NotificationBanner component
     */

    const removeNotification = (id: number) => {
        notifications.value = notifications.value.filter(n => n.id !== id)
    }

    /**
     * Convert a route to a notification action, set the default name to "open"
     */

    const getRouteAction = (to: RouteLocationRaw) => {
        const {$i18n} = useNuxtApp() as any
        return {
            name: $i18n.t("open"),
            callback: async () => await useRouter().push(to)
        }
    }

    interface NotificationOptions {
        timeout?: number,
        permanent?: boolean
        pauseOnHover?: boolean
    }

    /**
     * Methods that will be available to the component
     */

    const success = (title: string, message?: string, to?: RouteLocationRaw, options?: NotificationOptions) => {
        queueNotification(
            title,
            "success",
            options?.timeout,
            message,
            undefined,
            to ? getRouteAction(to) : undefined,
            options?.permanent,
            options?.pauseOnHover
        )
    }

    const error = (title: string, message?: string, to?: RouteLocationRaw, options?: NotificationOptions) => {
        queueNotification(
            title,
            "error",
            options?.timeout,
            message,
            undefined,
            to ? getRouteAction(to) : undefined,
            options?.permanent,
            options?.pauseOnHover
        )
    }

    const warning = (title: string, message?: string, to?: RouteLocationRaw, options?: NotificationOptions) => {
        queueNotification(
            title,
            "warning",
            options?.timeout,
            message,
            undefined,
            to ? getRouteAction(to) : undefined,
            options?.permanent,
            options?.pauseOnHover
        )
    }

    const info = (title: string, message?: string, to?: RouteLocationRaw, options?: NotificationOptions) => {
        queueNotification(
            title,
            "info",
            options?.timeout,
            message,
            undefined,
            undefined,
            options?.permanent,
            options?.pauseOnHover
        )
    }

    const custom = (title: string, icon: string, message?: string, to?: RouteLocationRaw, type?: keyof Variants, options?: NotificationOptions) => {
        queueNotification(
            title,
            type ?? 'info',
            options?.timeout,
            message,
            icon,
            to ? getRouteAction(to) : undefined,
            options?.permanent,
            options?.pauseOnHover)
    }

    const action = (title: string, icon: string, action: Action, message?: string, type?: keyof Variants, options?: NotificationOptions) => {
        queueNotification(
            title,
            type ?? 'info',
            options?.timeout,
            message,
            icon,
            action,
            options?.permanent,
            options?.pauseOnHover
        )
    }

    return {
        success,
        error,
        warning,
        info,
        custom,
        action,
        removeNotification,
        notifications
    }
}