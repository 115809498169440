export default {
    title: 'Users',
    email: 'E-Mail',
    customName: 'Name',
    firstname: 'First name',
    role: 'Role',
    role_name: 'Role',
    created_at: 'Created at',
    id: 'ID',
    impersonate: 'Impersonate',
    organisationName: 'Organisation name',

    qf_onlyEmployees: 'Only employees'
}
