export default {
    home: 'Home',
    dashboard: 'Dashboard',
    users: 'Users',
    organisations: 'Organisations',
    settings: 'Settings',
    myProfile: 'My Profile',
    information: 'Information',
    secret: 'Secret',
    nothingToSeeHere: "There's nothing to see here 🙈",
    escapeRoom: '//farbcode Dungeon Escape! 🏰',
    logout: 'Sign out',
    loginProviders: 'Login Methods'
}
