export default {
    defaultPlaceholder: 'Schreibe etwas...',
    features: {
        bold: 'Fett',
        italic: 'Kursiv',
        strike: 'Durchgestrichen',
        h1: 'Überschrift 1',
        h2: 'Überschrift 2',
        h3: 'Überschrift 3',
        code: 'Inline-Code',
        codeBlock: 'Code-Block',
        blockquote: 'Zitat',
        mention: 'Variable'
    }
}