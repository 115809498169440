import filter from './filter/main'
import tools from './tools/main'

export default {
    overview: 'Übersicht',
    totalEntries: 'Keine Ergebnisse |  Ein Eintrag wurde gefunden | Insgesamt wurden {count} Einträge gefunden',
    totalSelected: 'Keine Eintrag ausgewählt | Einen Eintrag markiert | {count} Einträge markiert',
    noEntries: 'Keine Einträge',
    labelPerPageOptions: 'Einträge pro Seite',
    createdAt: 'Erstellt',
    updatedAt: 'Zuletzt bearbeitet',

    actions: {
        filters: 'Filter',
        create: 'Erstellen',
        edit: 'Bearbeiten',
        tools: 'Werkzeuge',
        toolsSelected: 'Keine Einträge ausgewählt | Für einen ausgewählten Eintrag | Für {count} ausgewählte Einträge',
        filtersActive: 'Keine Filter aktiv | Ein Filter aktiv | {count} aktive Filter',
        refresh: 'Aktualisieren',
    },

    tools,
    filter
}