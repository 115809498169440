export default {
    title: 'My User',

    email: 'E-Mail',
    customName: 'Name',
    firstname: 'First name',
    role: 'Role',
    role_name: 'Role',
    created_at: 'Created at',
    id: 'ID',
    impersonate: 'Impersonate',

    qf_onlyEmployees: 'Only employees'
}
