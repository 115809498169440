export default {
    title: 'Create user',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'E-Mail',
    password: 'Password',
    passwordRepeat: 'Repeat password',
    createUser: 'Create user',
    createAndEditUser: 'Create and edit user',
    createUserSuccess: 'The user has been created successfully.',
    createUserError: 'An error occurred while creating the user.'
}