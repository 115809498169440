export default {
    general: 'General Information',
    currentVersion: 'Current Version',
    currentFeatures: 'Current Features',
    bugfixes: 'Bugfixes',
    isCurrentVersion: 'Most Current Version',
    loadNewestVersion: 'Load newest version',
    isOldVersion: 'Old Version',
    releaseNotes: 'Release Notes'
}
