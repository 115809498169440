<template>
    <NuxtLayout>
        <NuxtPage />
        <Teleport
            :disabled="teleportNotificationArea === false"
            :to="teleportNotificationArea ? '#notificationAreaRoot' : null">
            <NotificationArea position="top-right" />
        </Teleport>
    </NuxtLayout>
</template>

<script setup>
import {usePageTitle} from "@/composables/usePageTitle"

import {useLanguage} from '~/composables/useLanguage'

import {useStaticStore} from "~/store/static"

const staticStore = useStaticStore()


const {t, te} = useI18n()
const route = useRoute()
const {public: publicConfig} = useRuntimeConfig()
const pageTitle = usePageTitle()

const {toggle: langToggle} = useLanguage()
const hasMultiLanguageFeature = useFeature('multiLanguage')
const hasDarkModeFeature = useFeature('darkMode')

if (hasDarkModeFeature) {
    useDark({
        disableTransition: false
    })
}

const title = computed(() => {
    // custom title from pageTitle, has to be set through global state in order to be reactive (https://github.com/nuxt/nuxt/issues/13463)
    if (pageTitle.value) return pageTitle.value
    // global default title from translation file
    if (route.name) return te(`pageTitle.${route.name}`) ? t(`pageTitle.${route.name}`) : false
})

useHead(() => ({
    title: (title.value) ? `${publicConfig.appName} - ${title.value}` : publicConfig.appName
}))

const teleportNotificationArea = useState('teleportNotificationArea', () => false)


onMounted(() => {
    if(!hasMultiLanguageFeature) langToggle.value = false
    staticStore.loadStaticData()
})
</script>

<style>
.page-leave-active {
    transition: opacity 0.1s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(.05rem);
}

.layout-enter-active,
.layout-leave-active {
    transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
    filter: grayscale(1);
}
</style>