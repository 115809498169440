import { default as indexMtpr9XhNOVMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/auth/index.vue?macro=true";
import { default as indexcpXgpPSjZ8Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93VJZmo6a4XPMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/categories/[id].vue?macro=true";
import { default as indexy42PAA4I23Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93HZOS427g3yMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as index2XRNF8TjzhMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as indexg3PGo7GkklMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/mailTemplates/index.vue?macro=true";
import { default as baseDataPpw9aNiisnMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData.vue?macro=true";
import { default as indexEGqIdpg3AsMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/index.vue?macro=true";
import { default as orderBaseDataQi0c8LaXgNMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderBaseData.vue?macro=true";
import { default as orderDetailsQ6Os3rpWWGMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderDetails.vue?macro=true";
import { default as orderHistoryCTTItSxqhhMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderHistory.vue?macro=true";
import { default as orderOffersrIDLvCdS7aMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderOffers.vue?macro=true";
import { default as _91id_93ZH8LFJR8EDMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id].vue?macro=true";
import { default as indexysn86XB4PtMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/index.vue?macro=true";
import { default as organisationBaseDataqZcEVG5oa2Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93hyw0bEmaEeMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/[id].vue?macro=true";
import { default as indexGOC46abEbjMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/index.vue?macro=true";
import { default as organisations1eN0hPPMFKMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations.vue?macro=true";
import { default as dungeongrw6ucs6EEMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsZFCE3KOcmKMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexw6wcuefdkvMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index.vue?macro=true";
import { default as language3DVkR7lKtaMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/language.vue?macro=true";
import { default as _91id_93sCCJ8G7lxCMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexRaZNGdkIpWMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93qGT6GFiXK4Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexM4BrCdVZ2EMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingslYtR3bMGQXMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings.vue?macro=true";
import { default as userActivities6J4GEiOOEEMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataYqoTszjMpAMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsYscEskIL5AMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93PkLNc9XeFQMeta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id].vue?macro=true";
import { default as indexvtBPKCzFP1Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/index.vue?macro=true";
import { default as usersQdyhyjk6f6Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93mCON7mOxC9Meta } from "/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexMtpr9XhNOVMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexcpXgpPSjZ8Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData",
    path: "/baseData",
    meta: baseDataPpw9aNiisnMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData.vue").then(m => m.default || m),
    children: [
  {
    name: "baseData-categories-id",
    path: "categories/:id()",
    meta: _91id_93VJZmo6a4XPMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "categories",
    meta: indexy42PAA4I23Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "costCenters/:id()",
    meta: _91id_93HZOS427g3yMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "costCenters",
    meta: index2XRNF8TjzhMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-mailTemplates",
    path: "mailTemplates",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/baseData/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93ZH8LFJR8EDMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-orderBaseData",
    path: "orderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderDetails",
    path: "orderDetails",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderDetails.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderHistory",
    path: "orderHistory",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderHistory.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderOffers",
    path: "orderOffers",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/[id]/orderOffers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexysn86XB4PtMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: organisations1eN0hPPMFKMeta?.name,
    path: "/organisations",
    meta: organisations1eN0hPPMFKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93hyw0bEmaEeMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexGOC46abEbjMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingslYtR3bMGQXMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93sCCJ8G7lxCMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexRaZNGdkIpWMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93qGT6GFiXK4Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexM4BrCdVZ2EMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersQdyhyjk6f6Meta?.name,
    path: "/users",
    meta: usersQdyhyjk6f6Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93PkLNc9XeFQMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexvtBPKCzFP1Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93mCON7mOxC9Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241115115908/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]