import info from './info'
import menu from './menu'
import table from './table'

export default {
    organisationId: 'Organisation {id}',

    name: 'Name',
    email: 'E-Mail',
    membersCount: 'Members',

    deleteOrganisation: 'Delete organisation',
    deleteOrganisationText: 'Do you really want to delete this organisation? This action can not be undone.',
    deleteOrganisationSuccess: 'The organisation has been deleted successfully',
    deleteOrganisationError: 'An error occurred while deleting the organisation.',

    toggleState: 'activate / deactivate',
    toggleStateSuccess: 'The Organisation state has been updated successfully.',
    toggleStateError: 'An error occurred while updating the organisation state.',

    info,
    menu,
    table
}