export default {
    login: 'Login',
    logout: 'Logout',
    signIn: 'Sign in',
    stayLoggedIn: 'Stay logged in',
    forgotPassword: 'Forgot password?',
    registerHere: 'Register here',
    noAccountQuestion: 'No account yet?',
    pleaseWait: 'Please wait...',
    register: 'Register',
    registerText: 'Not a member?',
    registerCallToAction: 'Sign up',

    sessionTimeout: 'Your session has expired.',
    pleaseLoginAgain: 'Please log in again.',

    email: 'E-Mail',
    password: 'Password',
    repeatPassword: 'Repeat password',
    pleaseEnterNewPassword: 'Please enter your new password',
    savePassword: 'Save password',
    firstname: 'Firstname',
    lastname: 'Lastname',
    back: 'Back',
    forgotPasswordText: 'Please enter your email address. We will send you a link to reset your password.',
    forgotPasswordSuccess: 'We have sent you an email with a link to reset your password.',
    resetPassword: 'Reset password',
    resetPasswordSuccessTitle: 'Your password has been reset successfully',
    resetPasswordSuccessText: 'You can now log in with your new password.',
    socialRedirecting: 'Sie werden weitergeleitet...',
    socialError: 'Bei der Anmeldung ist ein Fehler aufgetreten.',
    orContinueWith: 'or continue with',
    providers: {
        google: 'Google',
        azure: 'Microsoft'
    }
}
