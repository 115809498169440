export default {
    filters: 'Filter',
    filter: 'Filter',
    clearAll: 'Filter zurücksetzen',
    yes: 'Ja',
    no: 'Nein',

    operator: {
        contains: 'enthält',
        oneOf: 'eine von',
        noneOf: 'keine von',
        yes: 'Ja',
        no: 'Nein',
        equals: 'ist gleich',
        startsWith: 'beginnt mit',
        endsWith: 'endet mit',
        greaterThan: 'ist größer als',
        lessThan: 'ist kleiner als',
        greaterThanOrEqual: 'ist größer oder gleich',
        lessThanOrEqual: 'ist kleiner oder gleich',
        notEquals: 'ist nicht gleich',
        inBetween: 'liegt im Interval',
        intervalFrom: 'von',
        intervalTo: 'bis'
    }
}