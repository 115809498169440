/*
*  core configuration for farbcode projects
*
*/
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import vueSelect from 'vue-select'
import 'v-calendar/style.css'

//import pinia and pinia persist
//import { createPinia } from 'pinia'
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

//setup pinia
//const pinia = createPinia()
//pinia.use(piniaPluginPersistedstate)


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('VSelect', vueSelect)

    nuxtApp.vueApp.use(setupCalendar, {})

    nuxtApp.vueApp.component('VCalendar', Calendar)
    nuxtApp.vueApp.component('VDatePicker', DatePicker)
})
