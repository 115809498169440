import table from './table'

export default {
    data: 'Daten',
    details: 'Details',
    requests: 'Bestellung',
    history: 'Historie',
    offers: 'Angebote',
    statusTree: 'Statusbaum',
    detailDocument: 'Detaildokument',
    name: 'Bezeichnung',
    offerObtainment: 'Angebotseinholung',
    visibleUntil: 'Ausschreibung sichtbar bis',
    description: 'Beschreibung',
    category: 'Kategorie',
    searchCategory: 'Kategorie suchen',
    costCenter: 'Kostenstelle',
    searchCostCenter: 'Kostenstelle suchen',
    costCenterManager: 'Kostenstellenverantwortlicher',
    alternativeRecipient: 'Alternativer Empfänger',
    recipient: 'Empfänger',
    recipient_room: 'Raumnummer',
    roomNumber: 'Raum {room}',
    quantity: 'Menge',
    unit: 'Einheit',
    offer: 'Angebot',
    searchOffer: 'Angebot suchen',
    date: 'Datum',
    conditions: 'Bedingungen',
    totalPrice: 'Gesamtpreis',
    orderId: 'Beschaffung',
    orderNumber: 'Beschaffung "{order_number}"',
    orderRemarks: 'Hinweise / Bemerkungen',
    overview: 'Übersicht',
    startTendering: 'Angebote einholen',
    tenderingExplanation: 'Um diese Beschaffung genehmigen zu lassen müssen Sie zuerst Angebote einholen. Tragen Sie ' +
        'dafür alle relevanten Daten in die Beschaffung ein. Danach können Sie über den Button "Angebote einholen" ' +
        'oben auf der Seite in den Status "Ausschreibung" wechseln. Ab dann können Lieferanten Ihre Beschaffung einsehen ' +
        'und Angebote abgeben.',
    reallyStartTendering: 'Wenn Sie die Angebotseinholung starten können die Daten zur Ausschreibung nicht mehr angepasst werden. Möchten Sie diese Beschaffungen mit den folgenden Daten zur Angebotseinholung freigeben?',

    subscribed: 'Von keinem Lieferanten abonniert|Von einem Lieferanten abonniert|Von {count} Lieferanten abonniert',
    tenderingBy: 'Angebot wird ausgeschrieben von',
    tenderingDescription: 'Beschreibung der Ausschreibung',
    tenderingCategory: 'Ausgeschriebene Kategorie',
    tenderingVisibleUntil: 'Ausschreibung sichtbar bis',
    noRestriction: 'Keine Einschränkung',
    file: 'Datei',

    positions: {
        title: 'Bestellwunsch',
        description: 'Beschreibung',
        number: 'Nr.',
        document: 'Dokumentupload',
        add: 'Position hinzufügen',
    },

    orderTypes: {
        order: 'Beschaffung',
        device: 'Standardgerät',
        special: 'Sonderbestellung',
        express: 'Eilbestellung',
    },
    
    request: {
        title: 'Beschaffung',
        recipient: 'Empfänger',
        desiredDeliveryDate: 'Gewünschtes Lieferdatum',
        visibleUntil: 'Sichtbar bis',
        details: 'Details (Dokumentupload)',
    },
    
    order: {
        title: 'Beschaffungsantrag',
        recipient: 'Empfänger',
        orderType: 'Art der Beschaffung',
        orderTypes: {
            firstOrder: 'Erstbeschaffung',
            replacementOrder: 'Ersatzbeschaffung',
            additionOrder: 'Ergänzungsbeschaffung',
            serviceOrder: 'Wartung',
            consumptionOrder: 'Verbrauchsmaterial',
        },
        reason: 'Grund für Auftragserteilung',
        reasons: {
            cheapestOffer: 'Günstigstes Angebot',
            contractPartner: 'Rahmenvertragspartner',
            onlyProvider: 'Einzelanbieter',
            mostEconomicalOffer: 'Wirtschaftlichstes Angebot',
        },
        additionalReason: 'Zusatztext zur Entscheidung',
        followUpCosts: 'Fallen Folgekosten an?',
        followUpCostsType: 'Art der Folgekosten',
        annualCosts: 'Jährliche Kosten',
        funding: 'Finanzierung der Folgekosten aus Fachbereichsmitteln?',
        isSpaceAvailable: 'Raum verfügbar?',
        room: 'Raum',
        isStaffAvailable: 'Personal verfügbar?',
        staff: 'Zuständig für Bedienung',
        supportStaff: 'Wartung und technische Betreuung durch',
        additionalInfo: 'Zusatzinformationen',
        dangerousGoods: 'Handelt es sich um Gefahrengut?',
        structuralMeasures: 'Bauliche Maßnahmen für die Aufstellung des Gerätes',
        orderDetails: 'Bestelltext'
    },

    device: {
        title: 'Standardgerät',
        device: 'Standardgerät',
        searchDevice: 'Gerät suchen',
    },

    special: {
        title: 'Sonderbestellung',
        deliveryDate: 'Lieferdatum',
        orderReason: 'Begründung für Sonderbestellung',
        reasons: {
            other: 'Sonstiger Grund',
            onlyProvider: 'Einzelanbieter',
            favorableOpportunity: 'Günstige Gelegenheit',
            followUpOrder: 'Folgeauftrag',
        },
        orderReasonText: 'Weitere Begründung',
    },

    express: {
        title: 'Eilbestellung',
        itemNumber: 'Artikelnummer',
        supplier: 'Lieferant',
        searchSupplier: 'Lieferant suchen',
        costs: {
            title: 'Kosten',
            unitPrice: 'Einzelpreis',
            transportCosts: 'Transportkosten',
        }
    },

    status: {
        open: 'Entwurf',
        updated: 'Korrigiert',
        placeholder: '...',
        cost_center_approved: 'KST genehmigt',
        data_center_approved: 'RZ genehmigt',
        tendering: 'Ausschreibung',
        finance_approved: 'FZ genehmigt',
        rejected: 'Abgelehnt',  
        closed: 'Geschlossen',  
        canceled: 'Storniert'
    },

    units: {
        pcs: 'Stück',
        pack: 'Packung',
        carton: 'Karton',
        bag: 'Beutel',
        bundle: 'Bund',
        bottle: 'Flasche',
        canister: 'Kanister',
        liter: 'Liter',
        roll: 'Rolle',
        license: 'Lizenz',
        classroomLicense: 'Klassenraum-Lizenz',
        campusLicense: 'Campus-Lizenz',
        pack10: '10er-Pack',
        kg: 'kg',
        ml: 'ml',
        g: 'g',
        meter: 'Meter',
        m2: 'm²',
        m3: 'm³',
    },

    deleteOrder: 'Beschaffung löschen',
    printOrderRequest: 'PDF Beschaffungantrag',
    printOrderForm: 'PDF Bestellformular',
    exportingOrder: 'Dokument wird erstellt...',
    deleteOrderText:
        'Möchtest du die Beschaffung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrderSuccess: 'Beschaffung wurde erfolgreich gelöscht.',
    deleteOrderError: 'Die Beschaffung konnte nicht gelöscht werden.',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    table
}