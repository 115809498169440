export default {
    label: 'Beschaffen',

    recipient: "Empfänger",
    total_price: "Gesamtpreis",
    offer: "Angebot",
    searchOffer: "Angebot suchen",

    createOrder: "Beschaffung anlegen",
    createOrderSuccess: 'Die Beschaffung wurde erfolgreich erstellt.',
    createOrderError: 'Die Beschaffung konnte nicht erstellt werden.',
}