import filter from './filter/main'
import tools from './tools/main'

export default {

    overview: 'Overview',
    totalEntries: 'No Results |  One result found | A total of {count} results found',
    totalSelected: 'None selected | One entry selected | {count} entries selected',
    labelPerPageOptions: 'Entries per page',

    actions: {
        filters: 'Filter',
        create: 'Create',
        edit: 'Edit',
        tools: 'Tools',
        toolsSelected: 'None selected | For one selected entry | For {count} selected entries',
        filtersActive: 'No filters active | One filter active | {count} active filters',
        refresh: 'Refresh'
    },

    tools,
    filter
}