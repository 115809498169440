export default {
    newest: 'Neueste',
    oldest: 'Letzte',
    noEntries: 'Keine Einträge gefunden',

    demo: {
        openAi: "Open Ai Demo"
    },

    admins: {
        title: "Admins",
        tableIndex: "Übersicht Admins"
    },

    organisations: {
        tableIndex: "Übersicht Organisationen"
    }
}