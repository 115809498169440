export default {
    title: 'Status ändern',

    toolExplanation: 'Ändern Sie den Status der ausgewählten Geräte auf Aktiv oder Inaktiv.',

    activateAction: 'Kein Gerät aktivieren | {count} Gerät aktivieren | {count} Geräte aktivieren',
    deactivateAction: 'Kein Gerät deaktivieren | {count} Gerät deaktivieren | {count} Geräte deaktivieren',

    activatedDevices: 'Die Geräte wurden erfolgreich aktiviert.',
    deactivatedDevices: 'Die Geräte wurden erfolgreich deaktiviert.'
}