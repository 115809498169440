export default {
    title: 'Create login method',
    provider: 'Provider',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    redirect: 'URL',
    tenant_id: 'Tenant ID',

    createProvider: 'Create provider',
    deleteProvider: 'Delete provider',

    noOrganisationFound: 'No organisation could be found.',
    providerAlreadyExists: 'A provider of this type already exists.',
    providerNotDeletable: 'This provider can not be deleted.',
    createProviderSuccess: 'The provider has been created successfully.',
    deleteProviderSuccess: 'The provider has been deleted successfully.',
    providers: {
        google: 'Google',
        azure: 'Azure'
    }
}