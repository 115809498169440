import table from './table'

export default {
    provider: 'Provider',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    redirect: 'URL',
    tenant_id: 'Tenant ID',

    createProvider: 'Create provider',
    deleteProvider: 'Delete provider',
    deleteProviderInfo:
        'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    noOrganisationFound: 'No organisation found.',
    providerAlreadyExists: 'A login method with this provider already exists.',
    providerNotDeletable: 'This login method can not be deleted.',
    deleteProviderSuccess: 'Login method was successfully deleted.',
    redirect_url: 'Redirect URL',

    providers: {
        google: 'Google',
        azure: 'Microsoft'
    },

    table
}