import {Ability} from '@casl/ability'
import {abilitiesPlugin} from '@casl/vue'
import {defineNuxtPlugin} from '#app'

export default defineNuxtPlugin((nuxtApp) => {

    const ability = new Ability([])

    //Use the created ability as the default one for the abilityPlugin
    nuxtApp.vueApp.use(abilitiesPlugin, ability, {useGlobalProperties: true})

    return {
        provide: {
            abilitySingleton: ability,
            can: nuxtApp.vueApp.config.globalProperties.$can
        }
    }
})