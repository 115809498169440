import categories from './categories/main'
import costCenters from './costCenters/main'
import devices from './devices/main'
import general from './general/main'
import loginProviders from './loginProviders/main'
import orders from './orders/main'
import organisations from './organisations/main'
import users from './users/main'

export default {
    selected: 'Ausgewählt',

    general,
    users,
    categories,
    costCenters,
    devices,
    organisations,
    loginProviders,
    orders
}